/* Header.css */

header {
  background-color: #333;
  padding: 20px;
  text-align: center;
}

nav {
  display: flex;
  justify-content: center;
}

a {
  color: white;
  text-decoration: none;
  padding: 10px;
}

a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 600px) {
  nav {
    flex-direction: column;
    align-items: center;
    
  }

  a {
    margin-bottom: 10px;
  }
}
