
:root {
    --primary-color: #212121;
    --background-color: #111;
    --font: sans-serif;
  }
  
  * {
    margin: 0;
    padding: 0;
  }

  .outer {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center cards horizontally */
  }
  
  body {
    background: var(--background-color);
    font-family: var(--font);
    display: flex;
    justify-content: center;
  }
  
  /* Timeline Container */
  .timeline {
    background: var(--primary-color);
    margin: 20px auto;
    padding: 20px;
    text-align: center;
    
  }
  
  /* Card container */
  .card {
    position: relative;
    max-width: 400px;
    
  }

  
  /* setting padding based on even or odd */
  .card:nth-child(odd) {
    padding: 30px 0 30px 30px;
  }
  
  .card:nth-child(even) {
    padding: 30px 30px 30px 0;
  }
  
  /* Global ::before */
  .card::before {
    content: "";
    position: absolute;
    width: 50%;
    border: solid rgb(77, 69, 173);
  }
  
  /* Setting the border of top, bottom, left */
  .card:nth-child(odd)::before {
    left: 0px;
    top: -4.5px;
    bottom: -4.5px;
    border-width: 5px 0 5px 5px;
    border-radius: 50px 0 0 50px;
  }
  
  /* Setting the top and bottom to "-5px" because earlier it was out of a pixel in mobile devices */
  @media only screen and (max-width: 400px) {
    .card:nth-child(odd)::before {
      top: -5px;
      bottom: -5px;
    }
  }
  
  /* Setting the border of top, bottom, right */
  .card:nth-child(even)::before {
    right: 0;
    top: 0;
    bottom: 0;
    border-width: 5px 5px 5px 0;
    border-radius: 0 50px 50px 0;
  }
  
  /* Removing the border if it is the first card */
  .card:first-child::before {
    border-top: 0;
    border-top-left-radius: 0;
  }
  
  /* Removing the border if it is the last card and it's odd */
  .card:last-child:nth-child(odd)::before {
    border-bottom: 0;
    border-bottom-left-radius: 0;
  }
  
  /* Removing the border if it is the last card and it's even */
  .card:last-child:nth-child(even)::before {
    border-bottom: 0;
    border-bottom-right-radius: 0;
  }
  
  /* Information about the timeline */
  .info {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally within the card */
    background: #333;
    color: gray;
    border-radius: 10px;
    padding: 10px;
  }
  
  /* Title of the card */
  .title {
    color: aliceblue;
    position: relative;
    font-style: italic;
  }
  


  /* Add this to your Timeline.css */
.quote {
  font-style: italic;
  color: aliceblue;
  font-size: larger;
  padding: 20px; /* Adjust padding as needed for size */
  border-radius: 10px; /* Adjust border radius for rounded corners */
  margin: 0 auto; /* Center the div horizontally */
  text-align: center; /* Center the text inside the div */
}

  
  /* text right if the card is even */
  .card:nth-child(even) > .info > .title {
    text-align: right;
  }
  
  /* setting dot to the left if the card is odd */
  .card:nth-child(odd) > .info > .title::before {
    left: -45px;
  }
  
  /* setting dot to the right if the card is odd */
  .card:nth-child(even) > .info > .title::before {
    right: -45px;
  }
  
  /* Add some styles for the timeline image */
  .timeline-image {
    width: 100%;
    max-width: 190px;
    margin-top: 10px;
    border-radius: 5px;
  }
  
  /* Make the timeline responsive */
  @media only screen and (max-width: 768px) {
    .card {
      max-width: 100%;
    }
  }
  